<template>
    <bs-popup id="mapping-aircraft-popup" @on-popup-closed="onPopupClosed" :auto-show="true" :isLarge="true" ref="form">
        <template v-slot:title>Create Purchase Order</template>
        <template v-slot:content>
            <invoice-receipt-popup ref="invoiceReceiptPopup" :vendorId="model.vendorId" @addRowData="addRowData" />
            <bs-error-message :errors="errorMessages" />
            <div class="row">
                <div class="col-sm-6">
                    <div class="mb-3 row">
                        <label class="col-sm-4 form-label">Vendor</label>
                        <div class="col-sm-8">
                            <vendor-combo v-model="model.vendorId" :textDefault="model.vendorCode" @onBeforeValueChanged="onBeforeVendorChanged" />
                        </div>
                    </div>
                </div>    
                <div>Total Data : {{ this.model.purchaseOrderDetails.length }}</div>
                <div class="col-sm-6">

                </div>
            </div>

            
            <div class="row">
                <div class="table-responsive" style="overflow:scroll; height: 66vh;">
                    <table class="table table-stripped">
                        <thead>
                            <tr>
                                <th>Vendor Invoice</th>
                                <th>Invoice Date</th>
                                <th>Submit Date</th>
                                <th>Receipt Number</th>
                                <th>Delivery Date</th>
                                <th>Qty</th>
                                <th>Unit</th>
                                <th>Total Price</th>
                                <th>Curr</th>
                                <th>SO</th>
                                <th>PR</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="model.purchaseOrderDetails != null && model.purchaseOrderDetails.length > 0">
                                <tr v-for="item in model.purchaseOrderDetails" :key="item.rowId">
                                    <td>{{ item.vendorInvoice }}</td>
                                    <td><view-date v-model="item.invoiceDate" /></td>
                                    <td><view-date v-model="item.submitDate" /></td>
                                    <td>{{ item.receiptNumber }}</td>
                                    <td><view-date v-model="item.deliveryDate" /></td>
                                    <!-- <td><bs-plain-number v-model="item.qtyInGal" /></td> -->
                                    <td><bs-plain-number v-model="item.qty" /></td>
                                    <td>{{ item.unitCode }}</td>
                                    <td><bs-plain-number v-model="item.totalPrice" /></td>
                                    <td>USD</td>
                                    <td>{{ item.soNumber }}</td>
                                    <td>{{ item.prNumber }}</td>
                                    <td><button class="btn btn-sm btn-danger" @click="removeRow(item)">Remove</button></td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
                <div class="mt-3">
                    
                    <button class="btn btn-sm btn-secondary" @click="addInvoiceReceipt">Add</button>
                </div>
            </div>
            
        </template>
        <template v-slot:footer>
            <button class="btn btn-sm btn-primary" @click="save"><i class="bi bi-check-circle"></i> Save</button>
            <button class="btn btn-sm btn-secondary" @click="close"><i class="bi bi-x-circle"></i> Cancel</button>
        </template>
    </bs-popup>
</template>

<script>
import PurchaseOrderService from "@/services/purchase-order-service.js";
import ViewDate from '../../../components/ViewDate.vue';
import InvoiceReceiptPopup from '../../../components/popup/InvoiceReceiptPopup.vue';

export default {
    components: { ViewDate, InvoiceReceiptPopup },
    data: function () {
        return {
            model: PurchaseOrderService.default(),
            errorMessages: null,
            rowId: 0
        }
    },
    methods: {
        onPopupClosed() {
            this.$router.push('/purchase-order');
        },
        async save() {
            try {
                this.$store.commit('progressCounterQueueIncrement');
                
                const result = await PurchaseOrderService.create(this.model);
                const status = result.status;
                if (status) {
                    this.errorMessages = null;
                }
                else
                {
                    this.errorMessages = result.errorMessages;
                }

                if (!status) return;

                this.$refs.form.closePopup();
                this.$router.push(`/purchase-order/detail/${result.data.id}`);
            }
            finally 
            {
                this.$store.commit('progressCounterQueueDecrement');
            }
        },
        close() {
            this.$refs.form.closePopup();
        },
        addInvoiceReceipt() {
            if (this.model.vendorId === undefined || this.model.vendorId === null || this.model.vendorId === 0) {
                alert("Please choose vendor.");
                return;
            }
            this.$refs.invoiceReceiptPopup.showPopup();
        },
        addRowData(item) {
            if (this.model.purchaseOrderDetails.findIndex(f => f.invoiceDetailId === item.invoiceDetailId) >= 0) {
                return;
            }

            this.rowId++;
            this.model.purchaseOrderDetails.push({
                rowId: this.rowId, 
                id: 0,
                invoiceDetailId: item.invoiceDetailId,
                vendorInvoice: item.vendorInvoiceNo,
                invoiceDate: item.invoiceDate,
                submitDate: item.submitDate,
                receiptNumber: item.receiptNumber,
                deliveryDate: item.deliveryDate,
                qtyInGal: item.qtyInGal,
                qty: item.qty,
                unitCode: item.unitCode,
                totalPrice: item.totalPrice,
                soNumber: item.soNumber,
                prNumber: item.prNumber
            });
        },
        removeRow(item){
            const index = this.model.purchaseOrderDetails.findIndex(f => f.invoiceDetailId === item.invoiceDetailId);
            if (index >= 0) {
                this.model.purchaseOrderDetails.splice(index, 1);
            }
        },
        onBeforeVendorChanged(vendor) {
            if (this.model.vendorId === undefined || this.model.vendorId === null || vendor === undefined || vendor === null || vendor.id === undefined || vendor.id === null) 
            {
                this.model.purchaseOrderDetails = new Array();
            }
            else if (this.model.vendorId !== vendor.id) 
            {
                this.model.purchaseOrderDetails = new Array();
            }
        }
    }
}
</script>

<style scoped>
    th, td {
        white-space: nowrap;
    }
</style>